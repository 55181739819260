<template>
  <div>
    <div class="order-history-wrap emb-card pa-4" v-if="ordersData.length != 0">
      <v-data-table
        :headers="ordersHeaders"
        :items="ordersData"
        hide-default-footer
        disable-sort
        :items-per-page="-1"
      >
        <template v-slot:item.receipt_id="{ item }">
          <div v-if="item.receipt_id != undefined">
            {{ item.receipt_id }}
          </div>
          <div v-if="item.stripe_payment_invoice != undefined">
            {{ item.stripe_payment_invoice.replace("in_", "") }}
          </div>
        </template>
        <template v-slot:item.order_date="{ item }">
          {{ formatDate(item.order_date) }}
        </template>
        <template v-slot:item.total_amount_currency.amount="{ item }">
          <div v-if="item.total_amount_currency != undefined">
            {{
              $formatMoney(
                item.total_amount_currency.amount,
                2,
                item.total_amount_currency.currency,
                1
              )
            }}
          </div>
          <div v-else>N/A</div>
        </template>
        <template v-slot:item.total_amount="{ item }">
          <div v-if="item.total_amount != undefined">
            {{ $formatMoney(item.total_amount, 2, "USD") }}
          </div>
          <div v-else>N/A</div>
        </template>
        <template v-slot:item.credit="{ item }">
          <div v-if="item.order_type == 'INTERAC'">
            {{ $formatMoney(item.credit, 2, "USD") }}
          </div>
          <div
            v-else-if="
              item.order_type == 'STRIPE' &&
              item.total_amount_currency != undefined
            "
          >
            {{ $formatMoney(item.total_amount_currency.credit, 2, "USD") }}
          </div>
          <div v-else>N/A</div>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          {{ statusDescription(item.status, item.order_type) }}
        </template> -->
        <template v-slot:item.action="{ item }">
          <div v-if="item.status != 'STRIPE_CHECKOUT' && item.status != 'VOID'">
            <v-btn class="accent ma-1" @click="viewDetails(item)">
              {{ $t("message.customerHistory.seeDetails") }}
            </v-btn>
          </div>
          <div v-if="item.status == 'SOLD' && item.order_type != 'STRIPE'">
            <v-btn class="accent ma-1" @click="cancelOrder(item)">
              {{ $t("message.cancel") }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="order-history-wrap emb-card pa-4" v-else>
      {{ $t("message.myTicketsPage.noOrder") }}
    </div>
    <emb-delete-confirmation
      ref="cancelOrderConfirmationDialog"
      :message="$t('message.myTicketsPage.cancelOrder')"
      @onConfirm="onCancelOrder"
    >
    </emb-delete-confirmation>
  </div>
</template>

<script>
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUser", "getUserInfo"]),
  },
  props: ["exclude"],
  data() {
    return {
      customer_id: "",
      orders_fetched: false,
      ordersHeaders: [],
      ordersData: [],
      orderToCancel: null,
    };
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != undefined && !this.orders_fetched) {
        this.fetch_user_orders();
      }
    },
    "$i18n.locale": function () {
      this.updateHeaders();
    },
  },
  mounted() {
    this.customer_id = this.$route.params.id;
    if (this.getUser != undefined && !this.orders_fetched) {
      this.fetch_user_orders();
    }
    this.updateHeaders();
  },
  methods: {
    fetch_user_orders() {
      const ordersRef = collection(getFirestore(), "orders");
      const q = query(
        ordersRef,
        where("client_id", "==", this.customer_id),
        orderBy("order_date", "desc")
      );
      getDocs(q).then((querySnapshot) => {
        this.orders_fetched = true;
        var documents = [];
        querySnapshot.forEach((doc) => {
          documents.push(doc.data());
        });
        this.ordersData = documents;
      });
    },
    formatDate(timestamp) {
      var d = timestamp.toDate();
      const ye = new Intl.DateTimeFormat(this.getUserInfo.language, {
        year: "numeric",
      }).format(d);
      const mo = new Intl.DateTimeFormat(this.getUserInfo.language, {
        month: "short",
      }).format(d);
      const da = new Intl.DateTimeFormat(this.getUserInfo.language, {
        day: "2-digit",
      }).format(d);
      return `${da}-${mo}-${ye}`;
    },
    statusDescription(status, order_type) {
      if (status == "SOLD" && order_type == "STRIPE") {
        return this.$t("message.myTicketsPage.statusDesc.sold_stripe");
      } else if (status == "SOLD") {
        return this.$t("message.myTicketsPage.statusDesc.sold");
      } else if (status == "ASSIGNED") {
        return this.$t("message.myTicketsPage.statusDesc.assigned");
      } else if (status == "COMPLETED") {
        return this.$t("message.myTicketsPage.statusDesc.completed");
      } else if (status == "CANCELED") {
        return this.$t("message.myTicketsPage.statusDesc.canceled");
      }
    },
    updateHeaders() {
      this.ordersHeaders = [
        {
          text: this.$t("message.customerHistory.header.receiptNumber"),
          value: "receipt_id",
        },
        {
          text: this.$t("message.customerHistory.header.order_type"),
          value: "order_type",
        },
        {
          text: this.$t("message.customerHistory.header.price"),
          value: "total_amount",
        },
        {
          text: this.$t("message.customerHistory.header.priceCurrency"),
          value: "total_amount_currency.amount",
        },
        {
          text: this.$t("message.customerHistory.header.credit"),
          value: "credit",
        },
        {
          text: this.$t("message.customerHistory.header.status"),
          value: "status",
        },
        {
          text: this.$t("message.customerHistory.header.orderDate"),
          value: "order_date",
        },

        {
          text: "",
          value: "action",
        },
      ];
    },
    cancelOrder(order) {
      this.orderToCancel = order;
      this.$refs.cancelOrderConfirmationDialog.openDialog();
    },
    onCancelOrder() {
      this.$refs.cancelOrderConfirmationDialog.close();
      this.$store
        .dispatch("update_order", {
          update_values: { general_status: "CANCELED", status: "CANCELED" },
          docId: this.orderToCancel.id,
        })
        .then(() => {
          this.fetch_user_orders();
        });
      this.orderToCancel = null;
    },
    viewDetails(item) {
      this.$router.push("/admin-panel/order/" + item.id);
    },
  },
};
</script>

